import React from 'react';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { BtnMore } from '../components/Hero/Hero';

import image from '../assets/images/404.png';
import backgroundImage from '../assets/images/404.bg.png';

const Section = styled.section`
  min-height: 100vh;
  background-color: #3e0d9d;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${backgroundImage});
  background-size: cover;
  padding: 50px 10px;
  overflow: hidden;
`;

const Heading = styled.h1`
  font-size: 120px;
  text-align: center;
  color: #fff;
  font-weight: 100;
  margin-bottom: 60px;
  
  @media screen and (max-width: ${props => props.theme.breakpoints.desktopSm}) {
    font-size: 80px;
  }
  
  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    font-size: 50px;
    margin-bottom: 30px;
  }
  
  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    font-size: 36px;
  }
`;

const ImgWrapper = styled.div`
   position: relative;
   height: 382px;
   width: 400px;
   
   @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    height: 290px;
    width: 320px;
  }
`;

const StyledImg = styled.img`
  position: absolute;
  left: -230px;
  
  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    max-width: 600px;
    left: -185px;
  }
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Nie znaleziono" />
    <Section>
      <ImgWrapper>
        <StyledImg src={image} />
      </ImgWrapper>
      <Heading>Ups! Zabłądziłeś?</Heading>
      <BtnMore to="/">WRÓĆ DO STRONY GŁÓWNEJ</BtnMore>
    </Section>
  </Layout>
);

export default NotFoundPage;
